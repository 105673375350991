import React from 'react'
import PropTypes from 'prop-types'

import { LoadingScreen } from 'shared/components'
import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Card,
} from 'reactstrap'
import StoreAddress from './StoreAddress'
import GoogleMaps from './GoogleMaps'

class StoreLocator extends React.Component {
  state = {
    searchZipCode: '',
  }

  doZipCodeSearch = () => {
    const { searchZipCode } = this.state
    const { searchByZipcode } = this.props

    searchByZipcode(searchZipCode)
  }

  startTimeout = () => {
    this.timeout = window.setTimeout(this.doZipCodeSearch, 400)
  }

  onZipCodeChange = e => {
    const {
      target: { value },
    } = e
    if (value.length < 6 && /^\d{0,5}$/.test(value)) {
      this.setState({ searchZipCode: value }, () => {
        if (this.timeout) {
          window.clearTimeout(this.timeout)
        }
        if (this.state.searchZipCode.length === 5) {
          this.startTimeout()
        }
      })
    }
  }

  onStoreSelect = storeNumber => {
    const { selectStore } = this.props
    selectStore && selectStore(storeNumber)
  }

  useSelectedStore = () => {
    const { selectedStore, useThisStore } = this.props

    if (useThisStore && typeof useThisStore === 'function') {
      useThisStore(selectedStore)
    }
  }

  useThisStore = store => {
    const { useThisStore } = this.props

    if (useThisStore && typeof useThisStore === 'function') {
      useThisStore(store)
    }
  }

  render = () => {
    const {
      selectedStore,
      googleMapsUri,
      isClosestStore,
      loadingSearchResults,
      storeSearchResults,
      useThisStore,
      saving,
      searchByZipcode,
      allowSearch,
      googleDirectionsUri,
      mapMinHeight,
      getMoreResults,
      showPartner,
    } = this.props

    const { searchZipCode } = this.state

    return (
      <Container className="p-0" id="maptop">
        <Row>
          <Col sm="12" md="5" className="mb-2 mb-md-0">
            <div className="h-100 border border-dark">
              <div className="bg-secondary p-3" />
              {loadingSearchResults && !selectedStore && (
                <LoadingScreen inline>Locating your store</LoadingScreen>
              )}
              {selectedStore && (
                <React.Fragment>
                  <Row>
                    <Col>
                      <h4 className="ml-3 m-2">
                        Your {isClosestStore ? 'Nearest' : ''} Store
                      </h4>
                    </Col>
                  </Row>
                  <Row className="p-3">
                    <Col>
                      <StoreAddress
                        {...selectedStore}
                        showDetails
                        showPartner={showPartner}
                      />
                    </Col>
                  </Row>
                  {!!useThisStore && (
                    <Row className="mb-3 mt-3 ml-2 mr-2">
                      <Col lg={{ size: '8', offset: '2' }} sm={12}>
                        <Button
                          id="store_locator--button-select_to_continue"
                          color="primary"
                          className="w-100"
                          onClick={this.useSelectedStore}
                          disabled={saving}
                          data-test="button.selectToContinue"
                        >
                          Select to Continue
                        </Button>
                      </Col>
                    </Row>
                  )}
                </React.Fragment>
              )}
              {!selectedStore && !loadingSearchResults && (
                <p className="p-3">
                  Please select a store from the map or from below
                </p>
              )}
            </div>
          </Col>
          <Col sm="12" md="7">
            <div className="border border-dark">
              <GoogleMaps
                selectedStore={selectedStore}
                storeSearchResults={storeSearchResults}
                onStoreSelect={store => this.onStoreSelect(store.storeNumber)}
                googleMapsUri={googleMapsUri}
                minHeight={mapMinHeight}
              />
            </div>
          </Col>
        </Row>
        {googleDirectionsUri && (
          <Row>
            <Col className="mt-3">
              <Button
                id="store_locator--button-get_directions"
                color="primary"
                onClick={() => {
                  window.open(googleDirectionsUri)
                }}
                data-test="button.getDirections"
              >
                Get Directions
              </Button>
            </Col>
          </Row>
        )}
        {allowSearch && searchByZipcode && (
          <React.Fragment>
            <Row className="mt-4">
              <Col>
                <FormGroup>
                  <Label for="zipCodeSearch">
                    <h4 className="mb-0">Want to select a different store?</h4>
                  </Label>
                  <Input
                    type="tel"
                    id="zipCodeSearch"
                    placeholder="Enter ZIP code"
                    style={{ maxWidth: '300px' }}
                    value={searchZipCode}
                    onChange={this.onZipCodeChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm="12">
                <Row>
                  {!!storeSearchResults &&
                    storeSearchResults.map(store => (
                      <Col
                        xs="12"
                        sm="6"
                        md="4"
                        className="my-2 d-flex flex-column justify-content-between"
                        key={store.storeNumber}
                      >
                        <Card
                          body
                          className="h-100 d-flex-column justify-content-between"
                        >
                          <div>
                            <StoreAddress {...store} />
                          </div>
                          {!!useThisStore || (
                            <Button
                              id={`store_locator--button-select_${
                                store.storeNumber
                              }`}
                              href="#maptop"
                              color="primary"
                              size="sm"
                              className="w-100"
                              onClick={() =>
                                this.onStoreSelect(store.storeNumber)
                              }
                              data-test={`button.SelectStore-${
                                store.storeNumber
                              }`}
                            >
                              Select
                            </Button>
                          )}
                          {!!useThisStore && (
                            <Button
                              id={`store_locator--button-select_to_continue_${
                                store.storeNumber
                              }`}
                              color="primary"
                              size="sm"
                              className="w-100"
                              onClick={() => {
                                this.useThisStore(store)
                              }}
                              disabled={saving}
                              data-test="button.selectToContinue"
                            >
                              Select to Continue
                            </Button>
                          )}
                        </Card>
                      </Col>
                    ))}
                </Row>
                {loadingSearchResults && (
                  <Row>
                    <Col>
                      <LoadingScreen inline />
                    </Col>
                  </Row>
                )}
                {!loadingSearchResults &&
                  !!storeSearchResults &&
                  !!storeSearchResults.length && (
                    <Row>
                      <Col>
                        <Button
                          id="store_locator--button-select_load_more"
                          color="link"
                          onClick={getMoreResults}
                          className="py-3"
                          data-test="button.loadMore"
                        >
                          Load more
                        </Button>
                      </Col>
                    </Row>
                  )}
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Container>
    )
  }

  static propTypes = {
    selectedStore: PropTypes.shape({
      storeNumber: PropTypes.number,
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      postalCode: PropTypes.string,
      longitude: PropTypes.number,
      latitude: PropTypes.number,
      hours: PropTypes.arrayOf(
        PropTypes.shape({
          openTime: PropTypes.string,
          closeTime: PropTypes.string,
        })
      ),
    }),
    googleMapsUri: PropTypes.string,
    isClosestStore: PropTypes.bool,
    storeSearchResults: PropTypes.array,
    loadingSearchResults: PropTypes.bool,
    searchByZipcode: PropTypes.func,
    allowSearch: PropTypes.bool,
    getMoreResults: PropTypes.func,
    selectStore: PropTypes.func,
    useThisStore: PropTypes.func,
    saving: PropTypes.bool,
    googleDirectionsUri: PropTypes.string,
    mapMinHeight: PropTypes.number,
    showPartner: PropTypes.bool,
  }

  static defaultProps = {
    storeSearchResults: [],
  }
}

export default StoreLocator
