import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { Container, Row, Col, Card, CardTitle } from 'reactstrap'
import { INCORRECT_BRAND_LOGIN, ONLINE_STORE_BRAND_NAME } from 'constants/values'
import cognito from 'shared/cognito'
import { parseLocationSearch, parseLocationHash } from 'shared/utils'
import Branding from 'shared/branding'
import { Helmet, LoadingScreen } from 'shared/components'
import authService from 'authentication/service'
import LoginForm from '../LoginForm'
import { saveError } from 'shared/sentry'
import axios from 'axios'
import { getCookie, setCookie } from 'shared/cookie'
import { GLOBAL_PATHS, ENROLLMENT_PATHS } from 'constants/paths'
import getBlackbox from 'shared/Iovation'

const REACT_APP_GRAPHQL_URI = process.env.REACT_APP_GRAPHQL_URI
const REACT_APP_VERSION = process.env.REACT_APP_VERSION
const REACT_APP_AUTH_URI = process.env.REACT_APP_AUTH_URI

const TIMEOUT_ERROR =
  'Uh oh! This page has timed out due to inactivity (we do this to protect your information). The good news is your information was saved. To pick up where you left off, please log in.'
const SSO_TIMEOUT = 15000

const LoginPage = ({
  isAuthenticating,
  isLegacyAuthenticated,
  isAuthenticated,
  isEnrollment,
  signIn,
  singleSignOn,
  signOut,
  error,
  history,
  location,
  isMfaEnabled,
  unifiedHomepages,
  redirectToP360,
}) => {

  const getEmailFromLocation = () => {
    const { search, state = {} } = location
    let email = ''
    if (search) {
      email = parseLocationSearch(search).email || ''
    }

    if (email.length === 0 && state.email) {
      email = state.email
    }

    return email.toLowerCase()
  }

  const [emailState, setEmail] = useState(getEmailFromLocation())
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [willSingleSignOn, setWillSingleSignOn] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [brandRedirect, setBrandRedirect] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [skipResume, setSkipResume] = useState(false)
  const [redirectToEnroll, setRedirectToEnroll] = useState(false)
  const [isP360Redirect, setIsP360Redirect] = useState(false)
  const [isSSO, setIsSSO] = useState(false)
  const [isResumeApp, setIsResumeApp] = useState(false)

  const timeout = useRef()
  const isMounted = useRef()

  const doRedirect = async () => {
    const { state = {} } = location

    const isPocket360Domain = Branding.current.key === ONLINE_STORE_BRAND_NAME.P360

    const from = {
      pathname: (state.from && state.from.pathname) || isPocket360Domain ? GLOBAL_PATHS.MFA : GLOBAL_PATHS.ROOT,
    }

    if (isEnrollment) {
      signOut()
      setErrorMessage('Incorrect username or password.')
    } else if (isLegacyAuthenticated) {
      history.push(unifiedHomepages ? GLOBAL_PATHS.MFA : from)
    } else {
      const result = await axios.get(REACT_APP_GRAPHQL_URI
        .replace('/graphql', '/version'))

      if (result.data && result.data.version !== REACT_APP_VERSION) {
        window.location = from.pathname
      } else {
        const resume = await authService.shouldSkipResumeApplication(emailState.toLowerCase())
        if (resume && resume.skipResumeApplication) {

          if (isMounted.current) {
            setSkipResume(true)
          }
        } else {
          history.push(from)
        }
      }
    }
  }

  useEffect(() => {
    if (!isMounted.current) {
      window.scrollTo(0, 0)
      window.setTimeout(() => {
        setLoaded(true)
      }, 750)
    }
  })

  useEffect(() => {
    if ((isLegacyAuthenticated || brandRedirect) && isAuthenticated) {
      clearSSOTimeout()
      setWillSingleSignOn(false)
    }
    isMounted.current = true
  }, [isLegacyAuthenticated, isAuthenticated, brandRedirect])

  const handleSso = async () => {

    if (location.hash) {
      const ssoData = parseLocationHash(location.hash)
      const searchData = parseLocationSearch(location.search)
      if (canSSO(ssoData) && isMounted.current) {
        const isBrandRedirect = ssoData.brandRedirect
          ? ssoData.brandRedirect
          : searchData
            ? searchData.brandRedirect
            : undefined

        setWillSingleSignOn(true)
        timeout.current = window.setTimeout(() => {
          setWillSingleSignOn(false)
          setErrorMessage(TIMEOUT_ERROR)
          history.replace(GLOBAL_PATHS.LOGIN)
          saveError('SSO Timeout')
        }, SSO_TIMEOUT)

        singleSignOn(window.location, !isBrandRedirect).then(() => {
          setBrandRedirect(isBrandRedirect)
          setIsSSO(true)
          setIsP360Redirect(searchData.redirectToP360)
          setIsResumeApp(searchData.resumeApp)
          clearSSOTimeout()
        })
      } else {
        history.replace(GLOBAL_PATHS.LOGIN)
      }
    } else {
      signOut()
    }
  }

  // will mount
  useEffect(() => {
    handleSso()

    return () => {
      isMounted.current = false
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  },
    []
  )

  const handleSignIn = async (values) => {
    const { email, password } = values

    const convertedEmail = email.toLowerCase()

    const errorMessage = await signIn({ email: convertedEmail, password })
    if (!errorMessage || errorMessage.length === 0) {
      setCookie(document, '', 'showTUModal', 'true', 130)

      const auth = await authService.getAuthAttributes()
      if (auth.isPasswordless) {
        localStorage.setItem('isPasswordlessFlow', true)
      }
      if (
        (!auth.hasOpenApplication && auth.isCoreCustomer) ||
        (!auth.isCoreCustomer && !!auth.retailBoCode)
      ) {
        history.push(GLOBAL_PATHS.MFA)
      } else {
        await doRedirect()
      }
    } else if (errorMessage === INCORRECT_BRAND_LOGIN) {
      if (redirectToP360)
        await doRedirect()
      else
        brandLogin()
    } else {
      setErrorMessage(errorMessage)
      setSubmitted(false)
      if (isMfaEnabled) {
        try {
          await axios({
            method: 'post',
            url: REACT_APP_AUTH_URI,
            data: {
              email: convertedEmail,
              blackBox: getBlackbox(),
              type: 'failedLogin',
            },
          })
        } catch (err) {
          saveError(err)
        }
      }
    }
  }

  const onSubmit = async ({ values }) => {
    setErrorMessage('')
    setSubmitted(true)
    await handleSignIn(values)
  }

  const onChange = ({ target }) => {
    if (target.name === 'email') {
      setEmail(target.value)
    } else {
      setPassword(target.value)
    }
  }

  const clearSSOTimeout = () => {
    if (timeout.current) {
      window.clearTimeout(timeout.current)
      timeout.current = null
    }
  }

  const canSSO = (ssoData) => {
    if (ssoData.sso) {
      if (
        getCookie(document, 'id_token') &&
        getCookie(document, 'access_token')
      ) {
        return true
      }
    }

    if (ssoData.id_token && ssoData.access_token) {
      return true
    }

    return false
  }

  const loginError = () => {
    const { state = {} } = location
    return !errorMessage || errorMessage === ''
      ? state && state.timeout
        ? TIMEOUT_ERROR
        : error
      : errorMessage
  }

  const brandLogin = () => {
    const { partnerSiteUrl } = Branding.current
    window.location.href = `${partnerSiteUrl}?brandRedirect=true#id_token=${cognito.idToken
      }&access_token=${cognito.accessToken}`
    authService.signOut()
  }

  if (isAuthenticated && location.hash && !willSingleSignOn) {
    doRedirect()
  }

  if (isResumeApp && isAuthenticated) {
    return <Redirect
      to={GLOBAL_PATHS.APPLICATION}
    />
  }

  if (skipResume && isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: GLOBAL_PATHS.MFA,
          state: {
            skipOpenApplication: isP360Redirect || !isSSO,
            isSSO: !!isSSO,
          },
        }}
      />
    )
  }

  if (willSingleSignOn) {
    return <LoadingScreen>Establishing Credentials</LoadingScreen>
  }

  if ((isLegacyAuthenticated || brandRedirect) && isAuthenticated) {
    return <LoadingScreen>You were authenticated</LoadingScreen>
  }

  if (redirectToEnroll) {
    return <Redirect push={true} to={ENROLLMENT_PATHS.ENROLLMENT} />
  }

  return (
    <div>
      <Helmet brandName={Branding.current.name} pageName="Log in" />
      <Container>
        {loaded && (
          <Row>
            <Col>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <LoginForm
                  email={emailState}
                  password={password}
                  error={loginError()}
                  authenticating={isAuthenticating}
                  handleSubmit={onSubmit}
                  handleChange={onChange}
                  submitted={submitted}
                />
                <Card body className="auth-block">
                  <CardTitle tag="h3">Don't have an account?</CardTitle>
                  <span
                    className="pointer btn btn-outline-primary btn-wrap"
                    onClick={() => setRedirectToEnroll(true)}
                  >
                    Enroll Now
                  </span>
                </Card>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  )
}

LoginPage.propTypes = {
  isAuthenticating: PropTypes.bool.isRequired,
  isLegacyAuthenticated: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  isEnrollment: PropTypes.bool,
  signIn: PropTypes.func.isRequired,
  singleSignOn: PropTypes.func,
  signOut: PropTypes.func.isRequired,
  error: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
  redirectToP360: PropTypes.bool,
}

export default LoginPage
